import React from 'react';
import { array, bool, object, oneOfType, string } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import BreadCrumbsNav from 'yoda-site-components/lib/components/BreadCrumbNav/BreadCrumb';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './GalleryBreadCrumbs.css';
import getBreadCrumbInfo from '../../selectors/BreadCrumbs';
import { selectHideBreadCrumbsWhenNoNavigation } from '../../selectors/fromState';
import selectNoSearchResults from '../../selectors/NoSearchResults';
import { hasBreadCrumbLinks } from '../../helpers/GalleryHelper';

const cx = classNames.bind(styles);

const breadCrumbsClasses = `${dt([
    'align-middle',
    'inline-block',
    'text-left',
    'smOnly:overflow-auto',
    'smOnly:w-full',
])} ${cx('breadCrumbs')}`;

export const GalleryBreadCrumbs = ({
    breadCrumbInfo,
    hideBreadCrumbsWhenNoNavigation,
    deviceType,
    className,
}) => {
    return hasBreadCrumbLinks(breadCrumbInfo, deviceType, hideBreadCrumbsWhenNoNavigation) ? (
        <div
            className={`${dt(['w-full', 'sm:smOnly:overflow-auto smOnly:w-fullmin-h-[30px]'])} ${cx(
                'breadcrumb-container'
            )} ${className}`}
            data-automation-id="product-breadcrumbs"
        >
            <div className={breadCrumbsClasses}>
                <BreadCrumbsNav breadCrumbs={breadCrumbInfo} />
            </div>
        </div>
    ) : null;
};

GalleryBreadCrumbs.propTypes = {
    breadCrumbInfo: oneOfType([array, object]),
    deviceType: object,
    hideBreadCrumbsWhenNoNavigation: bool,
    className: string,
};

GalleryBreadCrumbs.defaultProps = {
    breadCrumbInfo: [],
    deviceType: {},
    hideBreadCrumbsWhenNoNavigation: false,
    className: '',
};

const mapStateToProps = (state, props) => ({
    breadCrumbInfo: state.context && !state.context.isNative ? getBreadCrumbInfo(state, props) : [],
    countOfSearchResults: state.countOfSearchResults,
    deviceType: state.context ? state.context.deviceType : {},
    noResults: selectNoSearchResults(state),
    hideBreadCrumbsWhenNoNavigation: selectHideBreadCrumbsWhenNoNavigation(state),
});

export default connect(mapStateToProps)(GalleryBreadCrumbs);
