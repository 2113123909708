/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';
import _values from 'lodash/values';
import { connect } from 'react-redux';
import { object, oneOfType, string } from 'prop-types';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import MessageBox from 'yoda-core-components/lib/components/MessageBox';

/* istanbul ignore next */
export class NoResultsCard extends PureComponent {
    static propTypes = {
        noProducts: oneOfType([string, object]),
    };

    static defaultProps = {
        noProducts: '',
    };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        this.setErrorMessageToDisplay(this.props);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setErrorMessageToDisplay(nextProps);
    }

    setErrorMessageToDisplay = (props) => {
        const { noProducts } = props;
        const errorMessageList = _values(noProducts);

        this.setState({
            errorMessages: errorMessageList,
        });
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessages: [],
        });
    };

    render() {
        const { errorMessages } = this.state;
        const { clearErrorMessage } = this;

        return errorMessages.map((message) => (
            <MessageBox
                key={message}
                message={message}
                messageType="error"
                showMessage={!!errorMessages.length}
                className={dt(['mb-6'])}
                showCloseButton
                closeHandler={clearErrorMessage}
            />
        ));
    }
}

const mapStateToProps = ({ noProducts }) => ({ noProducts });

export default connect(mapStateToProps)(NoResultsCard);
