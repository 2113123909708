import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import throttle from 'lodash/throttle';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { isMobileWidth, isTabletWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import FilterPanelConnected from '../FilterPanel/FilterPanel';

const FilterContent = () => {
    const [filterContentOffset, setFilterContentOffset] = useState(null);

    const {
        context: { featureFlags: { enableStickyFacet = false } = {} } = {},
        productSettings: { isFacetOpened = false } = {},
        sliderStatus = false,
    } = useSelector((store) => store);

    const isMobileView = isMobileWidth() || (isTabletWidth() && sliderStatus);

    /* istanbul ignore next */
    useEffect(() => {
        const handleScroll = throttle(() => {
            const header = document.querySelector('header') || {};
            const promotionalBanner = document.querySelector('#primaryHeader') || {};
            const galleryProductList = document.querySelector('#gallery-product-list') || {};
            const headerPosition = getComputedStyle(header).position;

            if (header.getBoundingClientRect().bottom < 0) setFilterContentOffset(0);
            else if (
                headerPosition === 'sticky' &&
                header.offsetTop !== promotionalBanner.clientHeight
            )
                setFilterContentOffset(header.clientHeight);
            else setFilterContentOffset(galleryProductList.offsetTop);
        }, 50);

        if (enableStickyFacet) {
            document.addEventListener('scroll', handleScroll);

            return () => {
                document.removeEventListener('scroll', handleScroll);
            };
        }

        return undefined;
    }, [filterContentOffset]);

    const wrapperStyles = dt([
        'lg:block',
        'p-0',
        'xl:block',
        'lg:block',
        'sm:hidden',
        enableStickyFacet && 'fixed',
        'basis-1/4',
        'z-[3]',
    ]);

    return !isMobileView && isFacetOpened ? (
        <div
            className={wrapperStyles}
            data-automation-id="wrapper"
            style={{
                top: enableStickyFacet ? filterContentOffset : null,
            }}
        >
            <FilterPanelConnected />
        </div>
    ) : null;
};

export default FilterContent;
