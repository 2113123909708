import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import MessageBox from 'yoda-core-components/lib/components/MessageBox/MessageBox';
import GallerySliderConnected from '../GallerySlider/GallerySlider';
import StoreFilterCategoryConnected from '../StoreFilterCategory/StoreFilterCategory';
import SortBy from '../SortBy/SortBy';
import { toggleSlider } from '../../actions/SliderActions';
import selectNoSearchResults from '../../selectors/NoSearchResults';
import { selectLastAction, selectDisablePrevNoResultsMessage } from '../../selectors/fromState';
import { prevNoResultsMessage } from '../../common/constants';
import { getURLParameterBykey } from '../../utils';

const btnStyles = dt([
    'font-open-sans-semibold',
    'sm:text-normal',
    'lg:text-small',
    'inline-flex',
    'items-center',
    'justify-center',
    'w-full',
    'h-full',
]);

const FilterHeader = () => {
    const state = useSelector((reduxState) => reduxState);
    const { countOfSelectedFilters = 0, sortOptions: { sortOptions = [] } = {} } = state;
    const [sortOption, setSortOption] = useState('sort');
    const dispatch = useDispatch();

    useEffect(() => {
        const sortParam = getURLParameterBykey('sort');
        if (sortOptions?.length > 0 && (sortParam || (!sortParam && sortOption !== 'sort'))) {
            const selectedOption = sortOptions?.find((option) => option?.selected === true);
            setSortOption(selectedOption?.name);
        }
    }, [sortOptions]);

    const searchResultCount = () => {
        const noResults = selectNoSearchResults(state);
        const { countOfSearchResults = 0 } = state;
        const resultsTitleClasses = dt([
            'font-open-sans-regular',
            'sm:mt-4',
            'md:mt-6',
            'text-black',
            'lg:text-medium',
            'smMd:text-center',
            'sm:text-sm',
            'smOnly:py-0',
            'smOnly:pl-0',
            'smOnly:pr-1',
            'smOnly:mx-1',
            'smOnly:mb-0',
            'whitespace-nowrap',
        ]);

        if (!noResults && countOfSearchResults) {
            return (
                <div className={resultsTitleClasses} data-automation-id="filterheader-results">
                    {`${countOfSearchResults} Result${countOfSearchResults > 1 ? 's' : ''}`}
                </div>
            );
        }
        return null;
    };

    const filterBtn = useMemo(() => {
        return (
            <button
                type="button"
                onClick={() => dispatch(toggleSlider())}
                className={btnStyles}
                data-automation-id="product-filter-button"
            >
                Filter
                {countOfSelectedFilters > 0 && (
                    <span className={dt(['ml-1'])}>{` (${countOfSelectedFilters})`}</span>
                )}
            </button>
        );
    }, [countOfSelectedFilters]);

    const sortBtn = useMemo(() => {
        return (
            <>
                <div className={`${btnStyles} ${dt(['absolute', 'capitalize'])}`}>{sortOption}</div>
                <SortBy />
            </>
        );
    }, [sortOption]);

    const renderPrevNoResultsMessageSection = () => {
        const { prevNoResults = false } = selectLastAction(state);
        const disablePrevNoResultsMessage = selectDisablePrevNoResultsMessage(state);

        if (prevNoResults && !disablePrevNoResultsMessage) {
            return (
                <MessageBox
                    automationId="noResultsInfoMsg"
                    hasClose={false}
                    level="section"
                    showMessage
                    title={prevNoResultsMessage}
                    type="information"
                />
            );
        }

        return null;
    };

    const renderFilterHeader = () => {
        const wrapperClasses = dt(['sm:mb-4', 'md:mb-6', 'lg:hidden']);
        const buttonContainerClasses = dt([
            'font-open-sans-bold',
            'text-black',
            'text-small',
            'flex-1',
            'sm:mb-4',
            'md:mb-6',
            'flex',
            'text-center',
            'gap-x-4',
            countOfSelectedFilters > 0 ? 'smOnly:!px-0' : '',
        ]);
        const filterHeaderBtnClasses = dt([
            'inline-block',
            'text-center',
            'w-1/2',
            'border',
            'border-solid',
            'border-gray-40',
            'rounded-lg',
            'h-10',
            'flex',
            'justify-center',
            'items-center',
            'relative',
            'border-gray-60',
        ]);
        const StoreFilterCategoryClasses = dt(['smOnly:mt-1', 'md:my-2']);

        return (
            <div className={wrapperClasses} data-component-name="FilterHeader">
                {renderPrevNoResultsMessageSection()}
                <div className={buttonContainerClasses}>
                    <div className={filterHeaderBtnClasses}>{filterBtn}</div>
                    <div className={filterHeaderBtnClasses}>{sortBtn}</div>
                </div>
                <StoreFilterCategoryConnected
                    customWrapperClasses={StoreFilterCategoryClasses}
                    hideCurbsidePickupLabel
                    parentSection="filterheader"
                />
                {searchResultCount()}
                <GallerySliderConnected />
            </div>
        );
    };

    return <>{renderFilterHeader()}</>;
};

export default React.memo(FilterHeader);
