import _get from 'lodash/get';
import _has from 'lodash/has';
import { GET_GALLERY_IRIS_SEO_CONTENT } from '../actionTypes/GalleryIrisActionTypes';
import {
    SET_COMMON_DATA,
    GET_GALLERY_DETAILS_BY_ID_SUCCESS,
    GET_GALLERY_DETAILS_BY_ID_ERROR,
    GET_GALLERY_DETAILS_TIMEOUT_ERROR,
    RESET_COMMON_DATA,
} from '../actionTypes/GalleryActionTypes';
import { compareUrls } from '../utils';

const defaultState = {
    gallery: false,
};
export default function commonReducer(state = defaultState, action) {
    // NOSONAR
    switch (action.type) {
        case SET_COMMON_DATA:
        /* eslint-disable no-fallthrough */
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            const hasProductData = _has(action.galleryDetails, 'organicZoneInfo.products');
            return {
                pdPageURL: action.galleryDetails.pdPageURL,
                type: action.galleryDetails.type,
                linkUrl: action.galleryDetails.linkUrl,
                keywordRedirectUrl: action.galleryDetails.keywordRedirectUrl,
                hasProductData,
                gallery:
                    (hasProductData && action.type !== SET_COMMON_DATA) ||
                    (!__SERVER__ && action.type === GET_GALLERY_DETAILS_BY_ID_SUCCESS),
                categoryName: _get(action.galleryDetails, 'categoryName'),
                requestUrl: _get(action.galleryDetails, 'requestUrl'),
                breadCrumDataFromSolar: _get(action.galleryDetails, 'breadCrumbInfo', []),
                galleryFacetReorderingIndicator: _get(
                    action.galleryDetails,
                    'facets.galleryFacetReorderingIndicator',
                    false
                ),
                keywordRedirectMessage: action.galleryDetails.keywordRedirectMessage || '',
                isSolarApihasError: false,
                discoveryEngine: action?.galleryDetails?.source,
            };
        }
        case GET_GALLERY_IRIS_SEO_CONTENT: {
            const canonicalURL = _get(action.payload.seoTags, 'canonicalURL', '');
            if (canonicalURL) {
                return {
                    ...state,
                    seoTitleTags: {
                        canonicalURL,
                    },
                };
            }
            return state;
        }
        case GET_GALLERY_DETAILS_BY_ID_ERROR: {
            return { ...state, gallery: true, isSolarApihasError: true };
        }
        case GET_GALLERY_DETAILS_TIMEOUT_ERROR: {
            return { ...state, gallery: true };
        }

        case RESET_COMMON_DATA: {
            if (action.initialLoad && !compareUrls(_get(state, 'seoTitleTags.canonicalURL'))) {
                return {
                    gallery: false,
                };
            }
            return state;
        }

        default:
            return state;
    }
}
